const Slideshow = {
    init: () => {
      $(".js-prev").click(function () {
        Slideshow.prev($(this));
      });
  
      $(".js-next").click(function () {
        Slideshow.next($(this));
      });
    },
  
    unInit: () => {
      $(".js-prev").off();
      $(".js-next").off();
    },
  
    next: (button) => {
      var slideshow = $(button)
        .parents(".js-slideshowContainer")
        .find(".js-slideshow");
      var el = $(slideshow).find(".js-slide.active");
      var i = $(slideshow).find(".js-slide").index(el);
      var length = $(slideshow).find(".js-slide").length;
      var nextEl =
        i > length - 2 ? $(slideshow).find(".js-slide").first() : $(el).next();
      Slideshow.change(el, nextEl, slideshow);
    },
  
    prev: (button) => {
      var slideshow = $(button)
        .parents(".js-slideshowContainer")
        .find(".js-slideshow");
      var el = $(slideshow).find(".js-slide.active");
      var i = $(slideshow).find(".js-slide").index(el);
      var length = $(slideshow).find(".js-slide").length;
      var prevEl = i < 1 ? $(slideshow).find(".js-slide").last() : $(el).prev();
      Slideshow.change(el, prevEl, slideshow);
    },
  
    change: (current, element, slideshow) => {
      var el =
        $(element) != undefined
          ? $(element)
          : $(slideshow).find(".js-slide").first();
      var i = $(slideshow).find(".js-slide").index(el) + 1;
      var num = i < 10 ? "0" + i : i;
      var caption = $(el).children().first().attr("alt");
      $(current).removeClass("active");
      $(el).addClass("active");
      $(slideshow).parent().find(".js-current").text(num);
      $(slideshow).parent().find(".js-caption").text(caption);
    },
  };
  
  export default Slideshow;
  