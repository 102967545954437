const Archive = {
    init: () => {
        $('.tags--elements').click(function () {
            let value = this.nodeValue
        });

    }
};

export default Archive;
