const Header = {
  init: () => {
    globalThis.$(".header__dropdown").click(function () {
      globalThis.$(".header__episodes").slideToggle();
      randomColorGradient('.header__episodes a', 'background')
    });

    globalThis.$(".exhibition__highlight__close").click(function () {
      $(this).parent().css('display','none')
    });

  }
};

function randomColorGradient(element, cssElement) {
  globalThis.$(element).each(function () {
    let attribute = globalThis.$(this).attr('color');
    let col = 'rgb(' + (Math.floor(Math.random() * 197) + 51) + ',' + (Math.floor(Math.random() * 197) + 51) + ',' + (Math.floor(Math.random() * 197) + 51) + ')';
    globalThis.$(this).css(cssElement, 'linear-gradient(to left,' + col + ', ' + attribute + ', ' + attribute + ', ' + attribute + ')')
  });
}

export default Header;
