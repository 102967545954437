const Project = {
    init: () => {
        try {
            $('.js-toggle').next().hide();

            $('.js-toggle').click(function () {
                $(this).next().slideToggle('slow');
                $(this).toggleClass('active');
                
                if ($('.js-toggle').hasClass("active")) {
                    $('.project__container__left--highlight').slideUp();
                }
                else {
                    $('.project__container__left--highlight').slideDown();
                }
    
            });
        }
        catch { }
    }
};
export default Project;
