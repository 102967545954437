import "./_lib/modernizr";
import "../scss/application.scss";
import jquery from "jquery";

window.$ = window.jQuery = jquery;

import Website from "./_modules/website";
import Home from "./_modules/home";
import Header from "./_modules/header";
import Archive from "./_modules/archive";
import Grid from "./_modules/grid";
import Slideshow from "./_modules/slideshow";
import Project from "./_modules/project";
// import Cookie from "./_modules/cookies"
import Loadeer from "loadeer";



$(document).ready(() => {
  Website.init();
  Home.init();
  Header.init();
  Archive.init();
  Project.init();
  Slideshow.init();
  // Cookie.init();
  const instance = new Loadeer();
  instance.observe();
});

// the grid should be launched after the font + img are loaded
$(window).on('load', () => { Grid.init(); })