const Home = {
  init: () => {
    function movingImage() {
      const img = $('.exhibition__img img');

      if (img.length > 0) {
        var offset = img.offset();
        function mouse(evt) {
          var center_x = (offset.left) + (img.width() / 2);
          var center_y = (offset.top) + (img.height() / 2);
          var mouse_x = evt.clientX; var mouse_y = evt.clientY;
          var radians = Math.atan2(mouse_x - center_x, mouse_y - center_y);
          var degree = (radians * (180 / Math.PI) * -1) + 90;
          img.css('-moz-transform', 'rotate(' + degree + 'deg)');
          img.css('-webkit-transform', 'rotate(' + degree + 'deg)');
          img.css('-o-transform', 'rotate(' + degree + 'deg)');
          img.css('-ms-transform', 'rotate(' + degree + 'deg)');
        }
        $(document).mousemove(mouse);
      }
    }

    // movingImage();

    // random colors
    function randomColor(element, cssElement) {
      $(element).each(function () {
        let col = 'rgb(' + (Math.floor(Math.random() * 197) + 51) + ',' + (Math.floor(Math.random() * 102) + 133) + ',' + (Math.floor(Math.random() * 197) + 51) + ')';
        $(this).css(cssElement, col);
      });
    }

    function randomColorGradient(element, cssElement) {
      $(element).each(function () {
        let col = 'rgb(' + (Math.floor(Math.random() * 197) + 51) + ',' + (Math.floor(Math.random() * 102) + 133) + ',' + (Math.floor(Math.random() * 197) + 51) + ')';
        let col1 = 'rgb(' + (Math.floor(Math.random() * 197) + 51) + ',' + (Math.floor(Math.random() * 102) + 133) + ',' + (Math.floor(Math.random() * 197) + 51) + ')';
        $(this).css(cssElement, 'linear-gradient(to left,' + col + ', ' + col1 + ')')
      });
    }

  


    randomColor('.grid__item__more', 'color');
    randomColorGradient('.header', 'background');
    randomColorGradient('.error', 'background');
    randomColorGradient('.cookie-message', 'background');
    randomColorGradient('html', 'background');

  }
};

export default Home;
