import jQueryBridget from "jquery-bridget";
import Isotope from "isotope-layout";

const Grid = {
  init: () => {
    jQueryBridget("isotope", Isotope, $);
    const isotopeOptions = {
      itemSelector: ".js-gridItem",
      layoutMode: "masonry",
      // stagger: 5,
      // transitionDuration: 300,
      masonry: {
        columnWidth: ".js-gridSizer",
      },
    };
    Grid.container = $(".js-grid").isotope(isotopeOptions);
    Grid.filterValues = new Array();

    $(".js-filtersItem").click(function () {
      if ($(this).hasClass("active")) {
        $(this).css('background', 'white')
        Grid.removeFilter(this);

      } else {
        Grid.addFilter(this);
        let col = 'rgb(' + (Math.floor(Math.random() * 197) + 51) + ',' + (Math.floor(Math.random() * 102) + 133) + ',' + (Math.floor(Math.random() * 197) + 51) + ')';
        let col1 = 'rgb(' + (Math.floor(Math.random() * 197) + 51) + ',' + (Math.floor(Math.random() * 102) + 133) + ',' + (Math.floor(Math.random() * 197) + 51) + ')';

        $(this).css('background', 'linear-gradient(to left,'+ col +', '+ col1 +')')
      }
    });

    $(".js-filtersTheme").click(function () {
      // Grid.removeTheme(this);
      
      $(".js-filtersItem").removeAttr("style")
      if ($(this).hasClass("active")) {
        $(this).removeClass("active");
        Grid.removeTheme(this);
      } else {
        Grid.addTheme(this);
      }
    });

    $(".js-gridMore").parent().click(function () {
      // let item = $(this).parents(".js-gridItem");
      // let body = $(item).find(".js-gridItemBody");

      let item = $(this).children().parents(".js-gridItem");
      let body = $(item).find(".js-gridItemBody");

      if ($(this).children().hasClass("active")) {
        $(this).children().removeClass("active");
        $(item).removeClass("active");
        if ($(body).length > 0) $(body).html("");
        Grid.container.isotope();
      } else {
        $(this).children().addClass("active");
        $(item).addClass("active");
        if ($(body).length > 0) {
          let html = null;
          let href = $(this).children().data("href");
          let ajax = $.get(href + "?ajax=1", function (data) {
            html = $(data).find(".js-ajax").html();
            return true;
          }).done(function () {
            $(body).html(html);
            Grid.container.isotope();
          });
        } else {
          Grid.container.isotope();
        }
      }
    });
  },

  removeFilter: (filter) => {
    var filterValue = $(filter).data("filter").toString();
    var index = Grid.filterValues.indexOf(filterValue);
    Grid.filterValues.splice(index, 1);
    var newValue = Grid.getFilter();
    Grid.container.isotope({ filter: newValue });
    Grid.checkFilters();
    $(filter).removeClass("active");
  },

  addFilter: (filter) => {
    $(".js-filtersTheme").removeClass("active");
    $(".js-gridStamp").remove();
    var filterValue = $(filter).data("filter").toString();
    Grid.filterValues.push(filterValue);
    var newValue = Grid.getFilter();
    Grid.container.isotope({ filter: newValue });
    Grid.checkFilters();
    $(filter).addClass("active");
  },

  addTheme: (filter) => {
    var filterValue = $(filter).data("theme").toString();
    Grid.filterValues.length = 0;
    Grid.container.isotope({ filter: filterValue });
    $(".js-filtersItem, .js-filtersTheme")
      .removeClass("active")
      .removeClass("inactive");
    $(filter).addClass("active");

    let html = null;
    let href = $(filter).data("href");
    let ajax = $.get(href + "?ajax=1", function (data) {
      html = $(data).find(".js-ajax").html();
      return true;
    }).done(function () {
      $(".js-gridStamp").remove();
      $(".js-grid").append(html);
      Grid.container.isotope("stamp", $(".js-gridStamp"));
      Grid.container.isotope();
    });
  },

  removeTheme: (filter) => {
    Grid.filterValues.length = 0;
    Grid.container.isotope({ filter: "" });
    $(filter).removeClass("active").removeClass("inactive");
    $(".js-gridStamp").remove();
    Grid.container.isotope();
  },

  getFilter: () => {
    var value = "";
    var obj = Grid.filterValues;
    for (var prop in obj) {
      value += obj[prop];
    }
    return value;
  },

  checkFilters: () => {
    var currentFilters = Grid.filterValues;
    var filteredItems = Grid.container.isotope("getFilteredItemElements");
    var possibleFilters = new Array();

    $(filteredItems).each(function (i, el) {
      var classList = $(el).attr("class").split(" ");
      var filteredClass = classList.filter(function (str) {
        return str.includes("filter-");
      });
      for (let index = 0; index < filteredClass.length; index++) {
        const element = filteredClass[index];
        possibleFilters.push("." + element);
      }
    });

    $(".js-filtersItem").addClass("inactive");
    for (let index = 0; index < possibleFilters.length; index++) {
      const element = possibleFilters[index];
      $('.js-filtersItem[data-filter="' + element + '"]').removeClass(
        "inactive"
      );
    }
  },
};

export default Grid;
